import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const YoutubeVideoPage = () => {
  return (
    <Layout style={{ height: '100vh' }}>
      <Content style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'calc(100% - 50px)', marginTop: '50px' }}>
        <div style={{ width: '100%', height: '0', paddingBottom: '56.25%', position: 'relative' }}>
          <iframe 
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            src="https://www.youtube.com/embed/DoxjUT57obI"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <div style={{ width: '100%', padding: '10px', backgroundColor: '#f0f0f0' }}>
          {/* Introduction */}
          <div style={{ background: 'white', padding: '15px', margin: '10px 0', color: 'black' }}>
            <strong>Introduction</strong>
            <p>Welcome to SeaText AI, your comprehensive tool for website optimization. This guide provides detailed instructions on how to use SeaText AI effectively, from installation to leveraging its advanced features for your website's success.</p>
          </div>

          {/* Installation */}
          <div style={{ background: 'white', padding: '15px', margin: '10px 0', color: 'black' }}>
            <strong>1. Installation</strong>
            <p>Initial Setup: To access the full range of features, SeaText AI needs to be installed on your website. Integration Process: Code Integration: Locate the JavaScript code provided by SeaText AI. This code is crucial for the initial setup. Website Integration: Embed the code into your website's HTML. Detailed instructions are available for various platforms, including WordPress. Efficiency: The process is designed to be quick and user-friendly, often taking less than a minute to complete.</p>
          </div>

          {/* Data Collection and Analysis */}
          <div style={{ background: 'white', padding: '15px', margin: '10px 0', color: 'black' }}>
            <strong>2. Data Collection and Analysis</strong>
            <p>Tracking Website Activity: Upon installation, SeaText AI begins to analyze your website, tracking URLs and page activities. Creating Variants: The tool generates different variants of text elements on your site, which are essential for testing and optimization.</p>
          </div>

          {/* Editing and Managing Variants */}
          <div style={{ background: 'white', padding: '15px', margin: '10px 0', color: 'black' }}>
            <strong>3. Editing and Managing Variants</strong>
            <p>Accessing Variants: Use the 'Variant Edit' feature to modify or review text variants. Selecting a Page: Choose a specific URL from your site to view and edit its text variants. Language Selection: If your site is multilingual, select the appropriate language to view the corresponding text variants.</p>
          </div>

          {/* Comprehensive Database Access */}
          <div style={{ background: 'white', padding: '15px', margin: '10px 0', color: 'black' }}>
            <strong>4. Comprehensive Database Access</strong>
            <p>Database Contents: The database includes original text from your site and the SeaText AI-generated variants. Dual Functionality: This setup facilitates both website translation and A/B testing, enhancing user engagement across different languages.</p>
          </div>

          {/* Custom Text Editing */}
          <div style={{ background: 'white', padding: '15px', margin: '10px 0', color: 'black' }}>
            <strong>5. Custom Text Editing</strong>
            <p>Direct Editing: Modify any text on your site to correct errors or test new content for better engagement and conversion rates. Flexibility: This feature supports creative experimentation with text to identify the most effective versions.</p>
          </div>

          {/* A/B Testing and Analytics */}
          <div style={{ background: 'white', padding: '15px', margin: '10px 0', color: 'black' }}>
            <strong>6. A/B Testing and Analytics</strong>
            <p>Detailed Metrics: Each text element is assigned a conversion rating, indicating its effectiveness. Analyzing Conversion Rates: Higher ratings suggest better performance. Text with over 10 in rating is likely contributing positively to sales. View Count Reliability: A view count under 1,000 might not yield reliable data, while counts over 1,000 are more indicative of true performance.</p>
          </div>

          {/* Page-Level Insights */}
          <div style={{ background: 'white', padding: '15px', margin: '10px 0', color: 'black' }}>
            <strong>7. Page-Level Insights</strong>
            <p>Overall Performance: Assess the conversion rates and views at the page level to understand broader content effectiveness.</p>
          </div>

          {/* Advanced Configuration Settings */}
          <div style={{ background: 'white', padding: '15px', margin: '10px 0', color: 'black' }}>
            <strong>8. Advanced Configuration Settings</strong>
            <p>A/B Testing Controls: Choose to enable or disable A/B testing or translation for specific pages. Traffic Distribution: Adjust how much of your traffic is exposed to the SeaText AI variants. Optimization Focus: Control the balance between displaying high-performing variants and experimenting with new ones to continuously improve site performance.</p>
          </div>

          {/* Strategic Experimentation */}
          <div style={{ background: 'white', padding: '15px', margin: '10px 0', color: 'black' }}>
            <strong>9. Strategic Experimentation</strong>
            <p>Focus Adjustment: Shift focus towards new variants to gather more data and refine your website's appeal across different demographics and languages. Balancing Strategy: Begin with a higher experimentation rate and gradually shift to stability as you identify successful variants.</p>
          </div>

          {/* Support and Further Assistance */}
          <div style={{ background: 'white', padding: '15px', margin: '10px 0', color: 'black' }}>
            <strong>10. Support and Further Assistance</strong>
            <p>Immediate Assistance: Utilize the support chat for quick help with any issues or questions. Extensive FAQs: A comprehensive FAQ section addresses technical queries, including integration with dynamic systems (e.g., React), internal portal applications, and customization options.</p>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default YoutubeVideoPage;