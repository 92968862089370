import React, { useState, useEffect } from 'react';

function SeatextdemoWordPress() {
  const [iframeHeight, setIframeHeight] = useState(10000);

  useEffect(() => {
    const iframe = document.getElementById('seatext-iframe');
    const contentHeight = iframe.contentDocument.body.scrollHeight;

    if (contentHeight < iframeHeight) {
      setIframeHeight(contentHeight);
    }
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <iframe
        id="seatext-iframe"
        src="https://seatext.com/wordpress-customer-instructions"
        style={{
          width: '100%',
          height: `${iframeHeight}px`,
          border: 'none',
          overflow: 'hidden',
        }}
        title="Seatext Demo"
        scrolling="no"
      />
    </div>
  );
}

export default SeatextdemoWordPress;