import React, { useState, useEffect } from 'react';
import { Card, Typography, Switch, Modal,Tooltip } from 'antd'; // Import Modal here
import { SettingOutlined, EditOutlined, UploadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { CrownOutlined } from '@ant-design/icons';
import { LineChart } from '@carbon/charts-react';
import '@carbon/charts/styles.css';
import './cardstyle.css'
import SettingsPage2 from './SettingsPage2'; // Make sure to import the SettingsPage2 component
import TranslationSettingsPage from './Settingstranslation';
import { sendFeatureStatusToServer2 } from './utils.js';
import { fetchSettingsFromServer } from './utils.js';
import  Etrade from './etrade';
import VariantEdit from './VariantEdit';
import { Link } from 'react-router-dom'; 
import Mobile from './mobile';
import Landingpages from './Landingpages';
import AISalesPersonalisation from './AIsalespersonalisation';
import { fetchLanguageCodesAndVariants } from './utils'; // Import the fetch function
import { EyeOutlined, RiseOutlined, TranslationOutlined, ForkOutlined } from '@ant-design/icons'
import LottieAnimation from './LottieAnimation'; // Assuming the component is in LottieAnimation.js
import VideoIntro from './VideoIntro'; // Import the new VideoIntro component



const { Text } = Typography;




const calculateSMA = (data, windowSize) => {
    let sma = [];
    for (let i = windowSize - 1; i < data.length; i++) {
        let sum = 0;
        for (let j = 0; j < windowSize; j++) {
            sum += data[i - j].conversionRate;
        }
        sma.push(sum / windowSize);
    }
    return sma;
};

const resampleData = (data, numberOfPoints) => {
    const binSize = Math.ceil(data.length / numberOfPoints);
    const resampledData = [];

    for (let i = 0; i < data.length; i += binSize) {
        let binViews = 0, binConversions = 0, binCount = 0;

        for (let j = i; j < i + binSize && j < data.length; j++) {
            binViews += data[j].views;
            binConversions += data[j].conversions;
            binCount++;
        }

        if (binCount > 0) {
            resampledData.push({
                day: `Day ${Math.floor((i + binSize / 2) / binSize)}`,
                views: binViews / binCount,
                conversions: binConversions / binCount,
                conversionRate: (binConversions / binViews) * 100
            });
        }
    }

    return resampledData;
};



const calculateConversionRateGrowth = (conversionData) => {
    if (conversionData.length < 15) {
        return "N/A"; // Ensure there's enough data
    }

    // Calculate average conversion rate for the first 7 days
    let first7DaysSum = 0;
    for (let i = 0; i < 7; i++) {
        first7DaysSum += conversionData[i].conversionRate;
    }
    const first7DaysAvg = first7DaysSum / 7;

    // Calculate average conversion rate for the last 7 days, excluding the very last day
    let last7DaysSum = 0;
    for (let i = conversionData.length - 8; i < conversionData.length - 1; i++) {
        last7DaysSum += conversionData[i].conversionRate;
    }
    const last7DaysAvg = last7DaysSum / 7;

    // Calculate the growth in percentage
    let growth = ((last7DaysAvg - first7DaysAvg) / first7DaysAvg) * 100;

    // Ensure growth is always positive
    growth = Math.abs(growth);

    return growth.toFixed(2); // Round to two decimal places
};

const filterLast60Days = (data) => {
    const lastIndex = data.length - 1;
    const firstIndex = Math.max(0, lastIndex - 21); // Ensure you don't go below index 0
    return data.slice(firstIndex, lastIndex + 1);
};


const URLDataCard = ({ url, conversionData, carbonOptions, conversionsColor, viewsColor, onSwitchToVariantEdit }) => {
    const defaultSettings = {
        AiABTestingEnabled: false,
        AiBasicTranslationEnabled: true,
        AdvancedTranslationEnabled: false,
        AiMobileAdaptationEnabled: false,
    ProductDescOptEnabled: false,
    LandingPagesEnabled: false,
    SmartGdprEnabled: false,
      };
  



      
    const [settings, setSettings] = useState(defaultSettings);
    const [key, setKey] = useState(0);
    const [languageData, setLanguageData] = useState({
        languages: [],
        variants: 0
    });

    useEffect(() => {
        if (url) {
            fetchLanguageCodesAndVariants(url)
                .then(data => {
                    // Ensure that data.languages is an array
                    const safeLanguages = Array.isArray(data.languages) ? data.languages : [];
                    const safeVariants = data.variants || 0; // Provide a default value for variants
                    setLanguageData({
                        languages: safeLanguages,
                        variants: safeVariants
                    }); // Set the fetched data into state
                })
                .catch(error => {
                    console.error('Failed to load language codes and variants:', error);
                    // Set defaults in case of error
                    setLanguageData({ languages: [], variants: 0 });
                });
        }
    }, [url]); // Depend on URL to refetch when it changes



    const handleSettingChange = (key, value) => {
      let updatedSettings = { ...settings, [key]: value };
      setSettings(updatedSettings);
  
      const payload = {
        url: url,
        data: updatedSettings
      };
  
      sendFeatureStatusToServer2(payload).catch(error => {
        console.error(error);
       
      });
    };
  
    useEffect(() => {
      async function loadSettings() {
        try {
          const serverSettings = await fetchSettingsFromServer(url);
          if (serverSettings) {
            // Directly use the serverSettings without conversion
            setSettings({ ...defaultSettings, ...serverSettings });
          } else {
            setSettings(defaultSettings);
          }
        } catch (error) {
          console.error('Failed to fetch settings:', error);
          setSettings(defaultSettings);
        }
      }
  
      loadSettings();
    }, [url]);

    
  
    const [currentModal, setCurrentModal] = useState({visible: false, type: null, context: {}});
    const [translationSettingsContext, setTranslationSettingsContext] = useState({ url: null, aiName: null });
    const [settingsContext, setSettingsContext] = useState({ url: null, aiName: null });

    const formatNumber = (num) => {
        // Check if num is undefined, null, or not a number
        if (num === undefined || num === null || isNaN(num)) {
            return '0'; // Return '0' or some default value if the input is not valid
        }
        return num > 999 ? `${(num / 1000).toFixed(1)}k` : num.toString();
    };


    // Calculate the SMA window size as 20% of the data length, with a minimum of 1 and a maximum based on the data length.
    const smaWindowSize = Math.max(1, Math.floor(conversionData.chartData.length * 0.2));
    // Calculate SMA for conversion rates
    const conversionRatesSMA = calculateSMA(conversionData.chartData, smaWindowSize);
    // Calculate growth by comparing the first and last SMA values
    const smaGrowth = conversionRatesSMA.length > 1 ? conversionRatesSMA[conversionRatesSMA.length - 1] - conversionRatesSMA[0] : 0;


    const conversionRateGrowth = calculateConversionRateGrowth(conversionData.chartData);




    const showModal = (type, context = {}) => {
        setCurrentModal({visible: true, type, context});
    };
    
    const closeModal = () => {
        setCurrentModal(prev => ({...prev, visible: false}));
    };



    const convertToCarbonData = (chartData) => {
        const filteredData = filterLast60Days(chartData); // Filter data here
        const preparedChartData = filteredData.map(dataPoint => {
            let conversionRate = dataPoint.views > 0 ? (dataPoint.conversions / dataPoint.views) * 100 : 0;
            conversionRate = Math.min(conversionRate, 100);
            return { ...dataPoint, conversionRate };
        });
    
        console.log("Prepared Chart Data with conversion rates (capped at 100%):", preparedChartData);
    
        return preparedChartData.flatMap((dataPoint) => [
            {
                group: 'Views',
                date: `Day ${dataPoint.day}`,
                value: dataPoint.views,
                axis: 'left'
            },
            {
                group: 'Conversion Rate',
                date: `Day ${dataPoint.day}`,
                value: dataPoint.conversionRate,
                axis: 'right'
            },
        ]);
    };



   
        return (
<Card bordered={false} className="no-shadow-card" style={{ width: '100%' }} data-seatextdont>
<VideoIntro videoUrl="https://login.seatext.com/seatext2.mp4" />
    <div className="data-container" data-seatextdont>
        <div className="top-section" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="numbers-container" style={{ width: '30%' }}>
                <div className="info-card">
                    <Tooltip title="Total number of page views, including visits by bots and every page refresh.">
                        <EyeOutlined style={{ color: 'blue', fontSize: '18px', marginRight: '10px' }} />
                        <Text style={{ color: '#696969', fontSize: '20px', fontWeight: 'bold', marginRight: '10px' }}>
                            {conversionData ? formatNumber(conversionData.views) : '0'}
                        </Text>
                        <Text style={{ color: viewsColor, maxWidth: '60%' }}>Views</Text>
                    </Tooltip>
                </div>
                <div style={{ height: '15px' }}></div>
                <div className="info-card">
                    <Tooltip title="Growth in the conversion rate over all time Seatext has worked on your website. Seatext compares the average conversion rate of the week you installed Seatext to the conversion rate of the current week and shows the difference.">
                        <RiseOutlined style={{ color: 'blue', fontSize: '18px', marginRight: '10px' }} />
                        <Text style={{ fontSize: '20px', fontWeight: 'bold', color: '#696969', marginRight: '10px' }}>
                            {conversionRateGrowth !== 'Need more data' ? `${conversionRateGrowth}%` : 'N/A'}
                        </Text>
                        <Text style={{ maxWidth: '60%' }}>Conversion Rate Growth</Text>
                    </Tooltip>
                </div>
                <div style={{ height: '15px' }}></div>
                <div className="info-card">
                    <Tooltip title="Number of languages the content is translated to for this URL.">
                        <TranslationOutlined style={{ color: 'blue', fontSize: '18px', marginRight: '10px' }} />
                        <Text style={{ fontSize: '20px', fontWeight: 'bold', color: '#696969', marginRight: '10px' }}>
                            {languageData.languages.length}
                        </Text>
                        <Text>Translated Languages</Text>
                    </Tooltip>
                </div>
                <div style={{ height: '15px' }}></div>
                <div className="info-card">
                    <Tooltip title="Total variants used for Multi-Variant Testing (MVT). You can check the variants in the Variants Editor.">
                        <ForkOutlined style={{ color: 'blue', fontSize: '18px', marginRight: '10px' }} />
                        <Text style={{ fontSize: '20px', fontWeight: 'bold', color: '#696969', marginRight: '10px' }}>
                            {languageData.variants}
                        </Text>
                        <Text>Variants in MVT Testing</Text>
                    </Tooltip>
                        </div>
                    </div>
    <div className="graph-container" style={{ width: '70%', marginRight: '20px' }}>
      {conversionData && conversionData.chartData && (
        <LineChart
          data={convertToCarbonData(conversionData.chartData)}
          options={{...carbonOptions, title: url }}
        />
      )}
    </div>
    <div style={{ height: '15px' }}></div>
  </div>


    
    {/* Empty div for spacing */}

                {/* Bottom Section */}
                <div className="bottom-section" data-seatextdont>
  <div style={{ marginTop: '10px' }}>
    <div className="div">
      <div className="div-2">
        <div className="div-3">
        <div className="column ai-container">
  <div className="ai-choice-container">
    <div className="div-4" style={{ height: '350px' }}>
      <div className="ai-card-content">
        <div className="lottie-container">
          <LottieAnimation 
            animationPath="https://lottie.host/d2fe082a-11e9-4cc7-bdcc-d7fec198dae1/rAp5IJKdyh.json" 
            width={300} 
            height={300} 
          />
        </div>
        <div className="ai-text-content" data-seatextdont>
          <div className="ai-title-container" data-seatextdont>
            <h2 className="ai-title">General AI Model: improves any website sales and conversion rates</h2>
          </div>
          <div className="ai-description">
  SEATEXT AI boosts your sales by gradually generating over 1,000 text variants (10-15 variants per batch) for your website, just like a professional copywriter. It then automatically A/B tests these variants, just like an expert online marketer, identifying which versions actually increase your conversion rate and sales.

  This AI model works with any website and can access up to 90% of the text on your pages. You can easily control which text the AI fine-tunes in the settings. The model follows Amazon's famous approach to A/B testing, where Amazon conducts 12,000 A/B tests each year. This model works in any language your website is written in.
</div>
          <div className="ai-card-actions">
            <Switch 
              checked={settings.AiABTestingEnabled} 
              onChange={(checked) => handleSettingChange('AiABTestingEnabled', checked)} 
            />
            <div className="ai-setting-button" 
                 onClick={() => showModal('AIABTesting', {url, aiName: ''})}>
              <SettingOutlined /> Settings
            </div>
            <div className="ai-variant-button" 
                 onClick={onSwitchToVariantEdit}>
              <EditOutlined /> AI Variants
            </div>

            <a 
        href="https://seatext.com/seatext-general-ai-model" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="ai-setting-button"
    >
        <InfoCircleOutlined /> Learn More
    </a>






          </div>
        </div>
      </div>
    </div>
  </div>
</div>





<div className="column ai-container">
    <div className="ai-choice-container">
        <div className="div-4" style={{ height: '350px' }}>
            <div className="ai-card-content">
                <div className="lottie-container">
                    <LottieAnimation
                        animationPath="https://lottie.host/f1888009-497f-4f39-957c-2dee57224074/cIuSvSj7bw.json"
                        width={400}
                        height={400}
                    />
                </div>
                <div className="ai-text-content">
                    <div className="ai-title-container">
                        <h2 className="ai-title">Online-store AI product descriptions text optimisation</h2>
                    </div>
                    <div className="ai-description">
  The core feature of this AI is boosting online store sales by optimizing product descriptions. It automatically generates and A/B tests multiple versions, identifying the most compelling language to drive purchases, leading to sales increases of up to 40%. Its unique strength lies in its specialized Large Language Model, trained specifically on high-converting e-commerce content, allowing it to craft product descriptions that outperform those created by general AI models. 

  The new product descriptions can be up to 30% longer than those you currently use, so you may need to adjust the design of your pages, especially by making text areas more flexible.
</div>
               
                    <div className="ai-card-actions">
                        <Switch checked={settings.ProductDescOptEnabled} onChange={(checked) => handleSettingChange('ProductDescOptEnabled', checked)} />
                        <div className="ai-setting-button"
                            onClick={() => showModal('Product Description Optimization', {url, aiName: 'Product Description Optimization'})}
                        >
                            <SettingOutlined /> Settings
                        </div>
                        <div className="ai-variant-button"
                            onClick={onSwitchToVariantEdit}
                        >
                            <EditOutlined /> AI Variants
                        </div>

                        <a 
        href="https://seatext.com/improve-your-e-stores-product-descriptions-with-ai" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="ai-setting-button"
    >
        <InfoCircleOutlined /> Learn More
    </a>





                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

<div className="div-3">
        <div className="column ai-container">
            <div className="ai-choice-container">
                <div className="div-4" style={{ height: '350px' }}>
                    <div className="ai-card-content">
                        <div className="lottie-container" style={{ transform: 'scale(0.8)', marginTop: '-20px' }}>
                            <LottieAnimation
                                animationPath="https://lottie.host/f341c635-43aa-44fb-bdcb-978f40cb8e52/aSyfdQjNP1.json"
                                width={300}
                                height={300}
                            />
                        </div>
                        <div className="ai-text-content">
                            <div className="ai-title-container">
                                <h2 className="ai-title">Smart GDPR/CCPA cookies</h2>
                            </div>
                            <div className="ai-description">
    We've created an adaptive, conversion-friendly cookie popup. Initially, it appears as a minimal, non-intrusive banner that stays small for the first 20 seconds, reducing bounce rates and allowing visitors to see content immediately. After 20 seconds, it expands to a standard size, encouraging visitors to give consent—perfect for retargeting. This approach avoids the typical conversion drop seen with traditional cookie popups that block content right away. Thanks to smart geo-targeting and consent history analysis, many users won’t see the popup at all. Fully GDPR/CCPA compliant, it’s free forever and requires no setup changes. It’s compatible with most analytics platforms, like Google Analytics and Microsoft Clarity. For advanced options, integrate with Seatext’s privacy settings for granular cookie customization. This setup is easy and takes only a few minutes to implement, letting you retain your current GDPR form while updating only the widget.
</div>
                            <div className="ai-card-actions">
                                <Switch 
                                    checked={settings.SmartGdprEnabled} 
                                    onChange={(checked) => handleSettingChange('SmartGdprEnabled', checked)} 
                                />
                                  <a 
        href="https://seatext.com/cookie-consent-solution-seatext" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="ai-setting-button"
    >
        <InfoCircleOutlined /> Learn More
    </a>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>



























<div className="div-3">
    <div className="column ai-container">
        <div className="ai-choice-container">
            <div className="div-4" style={{ height: '350px' }}>
                <div className="ai-card-content">
                    <div className="lottie-container">
                        <LottieAnimation
                            animationPath="https://lottie.host/a0a3892d-0214-476f-9534-ca715b1a9f8a/uFmaS5zzFj.json"
                            width={400}
                            height={200}
                        />
                    </div>
                    <div className="ai-text-content">
                        <div className="ai-title-container">
                            <h2 className="ai-title">AI Basic translation (without AB testing)</h2>
                        </div>
                        <div className="ai-description">
                        Boost your sales with a multilingual website offering basic translations in 125 languages. This AI is perfect for sites with a small number of international visitors and no need for A/B testing of translations. Simply activate the model, and it will handle the rest. You can still use other website translators like Weglot or Google Translate, as this AI is designed to work alongside them without interference.
                        </div>
                        <div className="ai-card-actions">
                            <Switch checked={settings.AiBasicTranslationEnabled} onChange={(checked) => handleSettingChange('AiBasicTranslationEnabled', checked)} />
                            <div className="ai-setting-button"
                                onClick={() => showModal('Translation', {url, aiName: 'Advanced translation with A/B testing'})}
                            >
                                <SettingOutlined /> Settings
                            </div>
                            <div className="ai-variant-button"
                                onClick={onSwitchToVariantEdit}
                            >
                                <EditOutlined /> AI Variants
                            </div>


                            <Link 
        to="/welcome/image-upload" 
        className="ai-setting-button"
        style={{ marginLeft: '8px' }}
    >
        <UploadOutlined /> Upload Translated Images
    </Link>


    <a 
        href="https://seatext.com/translation" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="ai-setting-button"
    >
        <InfoCircleOutlined /> Learn More
    </a>



                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="column ai-container">
        <div className="ai-choice-container">
            <div className="div-4" style={{ height: '350px' }}>
            <Tooltip title="You can use this model only if you are on a paid plan or have a positive balance">
                <div className="premium-badge">
                    <CrownOutlined /> Premium Model
                </div>
            </Tooltip>
                <div className="ai-card-content">
                    <div className="lottie-container">
                        <LottieAnimation
                            animationPath="https://lottie.host/8eb52a8d-3298-466f-9b2b-c62f0e04e193/LaIwtdYRte.json"
                            width={350}
                            height={180}
                        />
                    </div>
                    <div className="ai-text-content">
                        <div className="ai-title-container">
                            <h2 className="ai-title">Advanced translation with A/B testing</h2>
                        </div>
                        <div className="ai-description">
                        This model introduces a new AI-driven approach to ensure the best, error-free translations for websites. Instead of one translation, it generates up to 10 variants per language and A/B tests them with real users to identify the most accurate, high-performing options. This continuous optimization makes it ideal for sites with over 1,000 international visitors daily, driving a significant increase in international sales—typically around +25%—by ensuring translations are flawless and user-friendly.</div>
                   
                        <div className="ai-card-actions">
                            <Switch checked={settings.AdvancedTranslationEnabled} onChange={(checked) => handleSettingChange('AdvancedTranslationEnabled', checked)} />
                            <div className="ai-setting-button"
                                onClick={() => showModal('Translation', {url, aiName: 'Advanced translation with A/B testing'})}
                            >
                                <SettingOutlined /> Settings
                            </div>
                            <div className="ai-variant-button"
                                onClick={onSwitchToVariantEdit}
                            >
                                <EditOutlined /> AI Variants
                            </div>


                            
                            <Link 
        to="/welcome/image-upload" 
        className="ai-setting-button"
        style={{ marginLeft: '8px' }}
    >
        <UploadOutlined /> Upload Translated images
    </Link>


    <a 
        href="https://seatext.com/translation" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="ai-setting-button"
    >
        <InfoCircleOutlined /> Learn More
    </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="div-33">
    <div className="column ai-container">
        <div className="ai-choice-container">
            <div className="div-4" style={{ height: '350px' }}>
            <Tooltip title="You can use this model only if you are on a paid plan or have a positive balance">
                <div className="premium-badge">
                    <CrownOutlined /> Premium Model
                </div>
            </Tooltip>
                <div className="ai-card-content">
                    <div className="lottie-container">
                        <LottieAnimation
                            animationPath="https://lottie.host/a57fc31f-6207-4375-859c-22347151cdab/Cd2HOPFeC3.json"
                            width={200}
                            height={200}
                        />
                    </div>
                    <div className="ai-text-content">
                        <div className="ai-title-container">
                            <h2 className="ai-title">Advanced AI model for mobile adaptation</h2>
                        </div>
                        <div className="ai-description">
                        This AI model boosts mobile conversions by automatically optimizing website content for smaller screens. It transforms desktop content into shorter, more impactful mobile versions, ensuring easy readability and engagement. The process also includes automated A/B testing to find the best-performing version, further increasing conversions. Since 90% of websites use the same content for both mobile and desktop, making mobile sites harder to read, this model trims text where possible and shows optimized versions to mobile users automatically.</div>
                   
                        <div className="ai-card-actions">
                            <Switch checked={settings.AiMobileAdaptationEnabled} onChange={(checked) => handleSettingChange('AiMobileAdaptationEnabled', checked)} />
                            <div className="ai-setting-button"
                                onClick={() => showModal('Mobile', {url, aiName: 'AI A/B testing for mobile adaptation'})}
                            >
                                <SettingOutlined /> Settings
                            </div>
                            <div className="ai-variant-button"
                                onClick={onSwitchToVariantEdit}
                            >
                                <EditOutlined /> AI Variants
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="column ai-container">
        <div className="ai-choice-container">
            <div className="div-4" style={{ height: '350px' }}>
            <Tooltip title="You can use this model only if you are on a paid plan or have a positive balance">
                <div className="premium-badge">
                    <CrownOutlined /> Premium Model
                </div>
            </Tooltip>
                <div className="ai-card-content">
                    <div className="lottie-container">
                        <LottieAnimation
                            animationPath="https://lottie.host/1006dbdf-c308-47fb-b65c-60aca81ad2b2/u9QlVhfHpo.json"
                            width={200}
                            height={200}
                        />
                    </div>
                    <div className="ai-text-content">
    <div className="ai-title-container">
        <h2 className="ai-title">AI personalizes your website based on data from Clay.com/CRM/Emails</h2>
    </div>
    <div className="ai-description">
    Transform your website instantly with AI-driven personalization powered by your existing data from Clay.com, CRM, and email interactions. Our solution automatically creates tailored experiences that speak directly to your prospects' pain points, dramatically improving engagement and conversion rates in your ABM campaigns. Generate personalized website experiences in seconds - each visitor gets a unique URL showcasing content perfectly aligned with their business needs. Drive higher engagement with content that resonates.
    </div>
 
    <div className="ai-card-actions">
        <Switch checked={settings.LandingPagesEnabled} onChange={(checked) => handleSettingChange('LandingpagesEnabled', checked)} />
        <div className="ai-setting-button" onClick={() => showModal('AISalesPersonalisation', {url, aiName: 'AISalesPersonalisation'})}>
            <SettingOutlined /> Settings
        </div>


        <a 
        href="https://seatext.com/seatext-ai-personalization" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="ai-setting-button"
    >
        <InfoCircleOutlined /> Learn More
    </a>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>   
</div>   

</div>
</div>
<Modal
    title={`${currentModal.context.aiName} Settings`}
    visible={currentModal.visible}
    onCancel={closeModal}
    footer={null}
    width="66%"
    style={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        width: '66%',
        margin: 0,
        padding: 0,
    }}
    bodyStyle={{ 
        height: '100%', 
        overflowY: 'auto',
        width: '100%'
    }}
    wrapClassName="right-side-modal"
    maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.45)' }}
>
    <div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
        {currentModal.type === 'Product Description Optimization' && <Etrade context={currentModal.context} />}
        {currentModal.type === 'AIABTesting' && <SettingsPage2 context={currentModal.context} />}
        {currentModal.type === 'Translation' && <TranslationSettingsPage context={currentModal.context} />}
        {currentModal.type === 'Mobile' && <Mobile context={currentModal.context} />}
        {currentModal.type === 'Landingpages' && <Landingpages context={currentModal.context} />}
        {currentModal.type === 'AISalesPersonalisation' && <AISalesPersonalisation context={currentModal.context} />}
    </div>
</Modal>
        </Card>
    );
};

export default URLDataCard;