import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';

const VideoIntro = ({ videoUrl, thumbnailMode }) => {
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    if (!thumbnailMode) {
      const videoFileName = videoUrl.split('/').pop().split('.')[0];
      const cookieName = `videoWatched_${videoFileName}`;
      
      const videoWatched = Cookies.get(cookieName);
      if (!videoWatched) {
        setShowVideo(true);
        Cookies.set(cookieName, 'true', { expires: 365 });
      }
    }
  }, [videoUrl, thumbnailMode]);

  const handleClose = () => {
    setShowVideo(false);
  };

  const handleVideoEnd = () => {
    setShowVideo(false);
  };

  const handleThumbnailClick = () => {
    setShowVideo(true);
  };

  return (
    <>
      <Modal
        visible={showVideo}
        onCancel={handleClose}
        footer={null}
        width="75%"
        bodyStyle={{ padding: 0, height: '75vh' }}
        centered
        closable={false}
        destroyOnClose={true}
      >
        <div style={{ position: 'relative', height: '100%' }}>
          <Button
            onClick={handleClose}
            type="text"
            icon={<CloseOutlined />}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              zIndex: 1,
            }}
          />
          <video
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
            autoPlay
            muted={false}
            onEnded={handleVideoEnd}
            controls
          >
            <source src={videoUrl} type="video/mp4" />
          </video>
        </div>
      </Modal>

      {thumbnailMode && (
        <div 
          onClick={handleThumbnailClick}
          style={{ 
            width: '100%',
            position: 'relative',
            paddingTop: '56.25%', // This creates 16:9 aspect ratio
            overflow: 'hidden',
            backgroundColor: '#000' // Optional: adds black bars if video doesn't fill completely
          }}
        >
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{ 
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              objectFit: 'contain'
            }}
          >
            <source src={videoUrl} type="video/mp4" />
          </video>
        </div>
      )}
    </>
  );
};

export default VideoIntro;