///seatext demo component
import React from 'react';

function Seatextdemo() {
  return (
    <div style={{ width: '100%' }}>
      <iframe
        src="https://seatext.com/seatext-demo"
        style={{ width: '100%', height: '10000px', border: 'none', overflow: 'hidden' }}
        title="How Seatext works"
        scrolling="no"
      />
    </div>
  );
}

export default Seatextdemo;