import React, { useState, useEffect } from 'react';
import { Typography, Card, Row, Col, Button, message, Switch, Input, Form, Collapse, Slider, Alert, Image } from 'antd';
import { PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { sendRequest, sendFeatureStatusToServer2, fetchSettingsFromServer } from './utils';

const { Title, Text } = Typography;
const { Panel } = Collapse;
const { TextArea } = Input;

function AISalesPersonalisation(props) {
  const defaultSettings = {
    TitleTaglineEnhancementEnabled: true,
    WebsiteContentEnhancementEnabled: true,
    CTAsOptimizationEnabled: true,
    MenuItemsEnhancementEnabled: true,
    CustomerReviewsEnhancementEnabled: true,
    LegalDocumentationEnhancementEnabled: false,
    TechnicalDocumentationEnhancementEnabled: true,
    ABtestingCreativityLevel: 3,
    ABtestingVariantsTnumber: 5,
    ABtestingConfidenceLevel: 85,
    ABtestingEarlyElimination: 65,
    ABtestingBrandVoicePreservation: 5,
    ABtestingTrafficPercentageForVariants: 1,
  };

  const [settings, setSettings] = useState(defaultSettings);
  const [modifiedUrl, setModifiedUrl] = useState('');

  useEffect(() => {
    let initialUrl = props.context?.url || '';
    setModifiedUrl(initialUrl);

    fetchSettingsFromServer(initialUrl)
      .then(serverSettings => {
        if (serverSettings) {
          setSettings({ ...defaultSettings, ...serverSettings });
        }
      })
      .catch(error => console.error('Failed to fetch settings:', error));
  }, [props.context?.url]);

  const handleSettingChange = (key, value) => {
    const updatedSettings = { ...settings, [key]: value };
    setSettings(updatedSettings);

    sendFeatureStatusToServer2({ url: modifiedUrl, data: updatedSettings })
      .catch(error => {
        console.error(error);
        message.error('Failed to update settings.');
      });
  };

  const renderTitle = () => {
    const titleBase = "AI improves landing pages ";
    return modifiedUrl ? `${titleBase} for ${modifiedUrl}` : titleBase;
  };

  const personalizationFeatures = [
    {
      title: "Titles, Taglines, and Short Phrases",
      key: "TitleTaglineEnhancementEnabled",
      image: "https://login.seatext.com/titles-taglines.png",
      description: "Enhance your website's titles, taglines, and short phrases with AI. This feature uses AI's contextual understanding to complement your content. Our AI tailors its suggestions based on your industry and the copy on your website. While AI can provide valuable insights, some suggestions may require manual review and adjustments to ensure optimal results.",
    },
    {
      title: "Website Content Enhancement",
      key: "WebsiteContentEnhancementEnabled",
      image: "https://login.seatext.com/website-content.png",
      description: "Enhance your website content using AI to boost user engagement. Adjust the AI Creativity Level to match your brand's voice and tone. Our AI will generate at least six different variants and track their performance through extensive A/B testing. It will continue to create new versions until it identifies the most effective one, ensuring the content not only resonates with your audience but also reinforces your brand identity.",
    },
    {
      title: "Calls-to-Action (CTAs) and Buttons",
      key: "CTAsOptimizationEnabled",
      image: "https://login.seatext.com/ctas-buttons.png",
      description: "AI rewrites calls to action and buttons based on your website's content and industry. This feature enables you to A/B test multiple options, helping you determine the most effective CTAs to convert your target audience and buyer personas.",
    },
    {
      title: "Menu Items",
      key: "MenuItemsEnhancementEnabled",
      image: "https://login.seatext.com/menu-items.png",
      description: "Utilize AI to refine your menu item descriptions, making them more engaging and clear. Due to the concise nature of menu texts, it is essential to prioritize accuracy. We strongly recommend monitoring the suggested variants in the Variant Editor. Supervision is crucial as this text experiment progresses to ensure the precision and appeal of each entry.",
    },
    {
      title: "Customer Reviews",
      key: "CustomerReviewsEnhancementEnabled",
      image: "https://login.seatext.com/customer-reviews.png",
      description: "Our AI technology refines customer reviews by improving language, correcting grammatical errors, and moderating tone. It enhances the readability and professionalism of the reviews while preserving the original sentiment. Please note that if you wish to remove negative reviews, this must be done manually.",
    },
    {
      title: "License Agreements",
      key: "LegalDocumentationEnhancementEnabled",
      image: "https://login.seatext.com/license-agreement-documentation.png",
      description: "AI can assist in reviewing and suggesting revisions to license agreements and other legal documents. However, due to the critical nature of these documents, we strongly advise against activating this feature without thorough oversight. If you decide to use this capability, it should be limited to preliminary drafts or non-essential documents. Ensure that all AI-generated modifications are meticulously reviewed to maintain legal accuracy and compliance.",
    },
    {
      title: "Technical Documentation",
      key: "TechnicalDocumentationEnhancementEnabled",
      image: "https://login.seatext.com/technical-documentation-1.png",
      description: "Our AI can assist in refining the presentation of technical information on your website. It carefully rephrases content to improve clarity and understanding, which is particularly beneficial for software companies and other tech-focused entities. This AI feature focuses on enhancing the wording without altering the original technical details. It also corrects grammatical errors and optimizes the text for better readability. By implementing A/B testing, we can determine which version of the technical description resonates best.",
    },
  ];

  return (
    <div style={{ width: '100%', maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
      <Card>
        <Title level={1} style={{ fontSize: '24px', fontWeight: 'bold', marginTop: '20px', marginLeft: '30px' }}>
          {renderTitle()}
        </Title>

        <Alert
          message="Important Information"
          description={
            <>
              This page allows you to control the scope of AI—determining what text AI can and cannot fine-tune. While there are several methods to limit AI scope, we recommend using the 'seatextdont' and 'seatextonly' commands as the most effective approach. You can find detailed information about these commands in our documentation at <a href="https://seatext.com/documentation-ai-scope" target="_blank" rel="noopener noreferrer">https://seatext.com/documentation-ai-scope</a>. We strongly advise using these methods first. The sliders below should only be used if you cannot edit your website directly or if you don't have time to manually create these tags.
            </>
          }
          type="info"
          showIcon
          icon={<InfoCircleOutlined />}
          style={{ marginBottom: '20px' }}
        />

        {personalizationFeatures.map((feature, index) => (
          <Card key={feature.key} style={{ marginBottom: '20px', background: index % 2 === 0 ? '#f5f5f5' : '#fff' }}>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Image src={feature.image} alt={feature.title} />
              </Col>
              <Col span={16}>
                <Title level={3}>{feature.title}</Title>
                <Text>{feature.description}</Text>
                <div style={{ marginTop: '10px' }}>
                  <Switch
                    checked={settings[feature.key]}
                    onChange={(checked) => handleSettingChange(feature.key, checked)}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                  />
                </div>
              </Col>
            </Row>
          </Card>
        ))}

        <Title level={2} style={{ marginTop: '40px' }}>AI and A/B Testing Settings</Title>

        <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
          <Col span={12}>
            <Text>AI Creativity Level:</Text>
            <Slider
              min={1}
              max={5}
              step={1}
              marks={{
                1: 'Max',
                2: 'High',
                3: 'Medium',
                4: 'Low',
                5: 'Min',
              }}
              value={settings.ABtestingCreativityLevel}
              onChange={(value) => handleSettingChange('ABtestingCreativityLevel', value)}
              tooltipVisible={false}
            />
          </Col>
          <Col span={12} style={{ paddingLeft: '50px' }}>
            <Text>
              Adjust how creative the AI should be. A lower setting means the AI will generate content that's significantly different from the original, while a higher setting limits creativity to minimal changes.
            </Text>
          </Col>
        </Row>

        <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
          <Col span={12}>
            <Text>Number of Variants to Create:</Text>
            <Slider
              min={2}
              max={8}
              step={1}
              marks={{
                2: '2',
                3: '3',
                4: '4',
                5: '5',
                6: '6',
                7: '7',
                8: '8',
              }}
              value={settings.ABtestingVariantsTnumber}
              onChange={(value) => handleSettingChange('ABtestingVariantsTnumber', value)}
              tooltipVisible={false}
            />
          </Col>
          <Col span={12} style={{ paddingLeft: '50px' }}>
            <Text>
              Choose the number of variant outputs. A lower number is suitable for sites with small traffic, while a higher number caters to websites with at least 30K visitors a month.
            </Text>
          </Col>
        </Row>

        <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
          <Col span={12}>
            <Text>Preserve Your Brand Voice (0-10):</Text>
            <Slider
              min={0}
              max={10}
              step={1}
              value={settings.ABtestingBrandVoicePreservation}
              onChange={(value) => handleSettingChange('ABtestingBrandVoicePreservation', value)}
              marks={{
                0: '0',
                5: '5',
                10: '10',
              }}
              tooltipVisible={false}
            />
          </Col>
          <Col span={12} style={{ paddingLeft: '50px' }}>
            <Text>
              Should AI copy your Brand voice characteristics, keeping it closer to your brand style, or have the ability to be more creative and depart from usual brand patterns? A higher value preserves your brand voice more closely.
            </Text>
          </Col>
        </Row>

        <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
          <Col span={12}>
            <Text>Confidence Level for A/B Testing:</Text>
            <Slider
              min={70}
              max={95}
              step={5}
              marks={{
                70: '70%',
                85: '85% Recommended',
                95: '95%',
              }}
              value={settings.ABtestingConfidenceLevel}
              onChange={(value) => handleSettingChange('ABtestingConfidenceLevel', value)}
              tooltipVisible={false}
            />
          </Col>
          <Col span={12} style={{ paddingLeft: '50px' }}>
            <Text>
              This slider adjusts the confidence level required to determine a winning variant in A/B testing. A higher confidence level means requiring more evidence (data) before making a decision, reducing the risk of false positives but potentially requiring more time and traffic.
            </Text>
          </Col>
        </Row>

        <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
          <Col span={12}>
            <Text>Early Elimination of Underperforming Variants:</Text>
            <Slider
              min={60}
              max={70}
              step={2}
              marks={{
                60: '60%',
                65: '65% Recommended',
                70: '70%',
              }}
              value={settings.ABtestingEarlyElimination}
              onChange={(value) => handleSettingChange('ABtestingEarlyElimination', value)}
              tooltipVisible={false}
            />
          </Col>
          <Col span={12} style={{ paddingLeft: '50px' }}>
            <Text>
              Adjust how quickly underperforming variants are discarded during A/B testing. A lower threshold is less aggressive, keeping variants longer for more data collection, while a higher threshold more quickly eliminates variants deemed underperforming based on early results.
            </Text>
          </Col>
        </Row>

        <Row gutter={[16, 24]} style={{ marginBottom: '50px', alignItems: 'top' }}>
          <Col span={12}>
            <Text>Introduce AI variants slowly to your visitors:</Text>
            <Slider
              min={1}
              max={6}
              step={1}
              marks={{
                1: '100%',
                2: '80%',
                3: '60%',
                4: '40%',
                5: '10%',
                6: '0% ',
              }}
              value={settings.ABtestingTrafficPercentageForVariants}
              onChange={(value) => handleSettingChange('ABtestingTrafficPercentageForVariants', value)}
              tooltipVisible={false}
            />
          </Col>
          <Col span={12} style={{ paddingLeft: '50px' }}>
            <Text>
              This percentage of traffic should see AI variants. Put it to 10% if you need to introduce AI variants to your visitors very slowly. Your original text will be tested in A/B mix in any case as one of the variants.
            </Text>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default AISalesPersonalisation;