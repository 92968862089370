import React, { useState, useEffect } from 'react';
import { Typography, Card, Row, Col, Slider, Switch, Transfer, message, Alert } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import { sendFeatureStatusToServer2,fetchSettingsFromServer } from './utils';




const { Title, Text } = Typography;
 

// Initialize an array of 50+ languages
const allLanguages = [
    "English", "Spanish", "French", "German", "Chinese", "Russian", "Japanese", "Korean", "Italian", "Dutch",
    "Portuguese", "Arabic", "Swedish", "Danish", "Finnish", "Norwegian", "Polish", "Turkish", "Czech", "Slovak",
    "Hungarian", "Romanian", "Bulgarian", "Greek", "Lithuanian", "Latvian", "Estonian", "Croatian", "Serbian",
    "Slovenian", "Macedonian", "Albanian", "Ukrainian", "Belarusian", "Armenian", "Azerbaijani", "Georgian",
    "Kazakh", "Uzbek", "Turkmen", "Tajik", "Kyrgyz", "Mongolian", "Persian", "Hebrew", "Hindi", "Bengali", "Thai",
    "Vietnamese", "Malay", "Indonesian", "Filipino", "Swahili", "Nepali", "Urdu", "Sinhala", "Khmer", "Lao",
    "Burmese", "Kurdish", "Amharic", "Zulu", "Somali", "Hausa", "Yoruba", "Igbo", "Cebuano", "Malagasy",
    "Sundanese", "Javanese", "Maori", "Pashto", "Tamil", "Telugu", "Kannada", "Malayalam", "Gujarati", "Marathi",
    "Oriya", "Punjabi", "Assamese", "Bosnian", "Sicilian", "Luxembourgish", "Corsican", "Samoan", "Tongan",
    "Fijian", "Greenlandic", "Inuktitut", "Aymara", "Quechua", "Guarani", "Tigrinya", "Maltese", "Kazakh Latin",
    "Sorbian", "Welsh", "Breton", "Irish", "Scottish Gaelic", "Manx", "Cornish", "Basque", "Catalan", "Galician",
    "Faroese", "Icelandic"
  ].map(lang => ({ key: lang, title: lang }));





  function Mobile(props) {
    // Define default settings
    const defaultSettings = {
      
      ABtestingCreativityLevel: 3,
      ABtestingVariantsTnumber: 5,
      ABtestingConfidenceLevel: 85,
      ABtestingEarlyElimination: 65,
      ABtestingBrandVoicePreservation: 5,
      ABtestingTrafficPercentageForVariants: 1,
      MobileTechinfoEnhancementEnabled: true,
MobileFriendlyReviewsEnhancementEnabled: true,
MobileOptimizedCTAsEnabled: true,
MobileContentOptimizationEnabled: true,
MobileTitlesEnhancementEnabled: true,
MobileOptEnabled: true,
    };
  
    const [settings, setSettings] = useState(defaultSettings);
    const [showAiABTestSwitch, setShowAiABTestSwitch] = useState(false);
    const [modifiedUrl, setModifiedUrl] = useState('');
  
    useEffect(() => {
      let initialUrl = props.context?.url || '';
      let isUrlFromCookies = false;
  
      if (!initialUrl) {
        const domainName = Cookies.get('domainName');
        if (domainName) {
          initialUrl = `${domainName} Whole website`;
          setShowAiABTestSwitch(true);
          isUrlFromCookies = true;
        }
      } else {
        setShowAiABTestSwitch(false);
      }
  
      setModifiedUrl(initialUrl); // Update the state with the modified URL
  
      async function loadSettings() {
        try {
          const serverSettings = await fetchSettingsFromServer(initialUrl);
          if (serverSettings) {
            setSettings({ ...defaultSettings, ...serverSettings });
          }
        } catch (error) {
          console.error('Failed to fetch settings:', error);
        }
      }
  
      loadSettings();
  
      return () => {
        if (isUrlFromCookies) {
          // Perform cleanup or additional actions when the component unmounts or URL changes
        }
      };
    }, [props.context?.url]); // Depend on `props.context.url` to re-run this effect when it changes
  
    const handleSettingChange = (key, value) => {
      const updatedSettings = { ...settings, [key]: value };
      setSettings(updatedSettings);
  
      const payload = {
        url: modifiedUrl, // Use the modified URL for server requests
        data: updatedSettings,
      };
  
      sendFeatureStatusToServer2(payload).catch(error => {
        console.error(error);
        message.error('Failed to update settings.');
      });
    };
  
    const renderTitle = () => {
      const titleBase = "AI improves conversion rate for mobile pages";
      return modifiedUrl ? `${titleBase} for ${modifiedUrl}` : titleBase;
    };
  




  return (
    <div style={{ width: '1400px', maxWidth: '1900px', minWidth: '1200px' }}>
      <div style={{ padding: '20px', display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '100%' }}>
          <Card style={{ padding: '20px 50px' }}>
          <Title 
      level={1} 
      align="left" 
      style={{ 
        fontSize: '24px', 
        fontWeight: 'bold',
        marginTop: '20px',  // Adds vertical spacing
        marginLeft: '30px'  // Adds horizontal spacing to the left
      }}
    >{renderTitle()}</Title>
  

  <Alert
  message="Important Information"
  description={
    <>
      This page allows you to control the scope of AI—determining what text AI can and cannot fine-tune. While there are several methods to limit AI scope, we recommend using the 'sea-textdont' and 'sea-textonly' commands as the most effective approach. You can find detailed information about these commands in our documentation at <a href="https://seatext.com/documentation-ai-scope" target="_blank" rel="noopener noreferrer">https://seatext.com/documentation-ai-scope</a>. We strongly advise using these methods first. The sliders below should only be used if you cannot edit your website directly or if you don't have time to manually create these tags.
    </>
  }
  type="info"
  showIcon
  icon={<InfoCircleOutlined />}
  style={{ marginBottom: '20px' }}
/>



<div
  style={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexGrow: 0,
    flexShrink: 0,
    position: "relative",
    background: "#fff",
  }}
>
  <div
    style={{
      flexGrow: 0,
      flexShrink: 0,
      width: 1086,
      height: 280,
      position: "relative",
      background: "#f5f5f5",
    }}
  >
    <div
      style={{
        width: 1086,
        height: 280,
        position: "absolute",
        left: "-0.5px",
        top: "-0.5px",
        background: "#f5f5f5",
      }}
    />
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "absolute",
        left: 1010,
        top: 28,
        gap: 5,
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 1,
        paddingBottom: 1,
        borderRadius: 16,
        background: "#1890ff",
      }}
    >
      <Switch
        checked={settings.MobileTitlesEnhancementEnabled}
        onChange={(checked) => handleSettingChange('MobileTitlesEnhancementEnabled', checked)}
        disabled={false}
        checkedChildren="ON"
        unCheckedChildren="OFF"
        icon={false}
        loading={false}
        size="medium"
        text
      />
    </div>
    <p
      style={{
        width: 468,
        position: "absolute",
        left: 438,
        top: 32,
        fontSize: 22,
        textAlign: "left",
        color: "rgba(0,0,0,0.85)",
      }}
    >
      Titles, Taglines, and Short Phrases
    </p>
    <p
      style={{
        width: 464,
        position: "absolute",
        left: 438,
        top: 60,
        fontSize: 16,
        textAlign: "left",
        color: "rgba(0,0,0,0.45)",
      }}
    >
      Craft captivating titles, taglines, and short phrases optimized for mobile screens. Our AI
      understands context and tailors suggestions specifically for mobile users, ensuring your
      content resonates with your audience on the go. While AI offers valuable insights, a manual
      review ensures every suggestion aligns perfectly with your brand and tone.
    </p>
    <img
      src="https://login.seatext.com/titles-taglines-mobile.png"
      style={{
        width: 362,
        height: 252,
        position: "absolute",
        left: 27,
        top: 27,
        objectFit: "cover",
      }}
    />
  </div>
  <div
    style={{
      flexGrow: 0,
      flexShrink: 0,
      width: 1086,
      height: 280,
      position: "relative",
      background: "#fff",
    }}
  >
    <div
      style={{
        width: 1086,
        height: 280,
        position: "absolute",
        left: "-0.5px",
        top: "-0.5px",
        background: "#fff",
      }}
    />
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "absolute",
        left: 1010,
        top: 28,
        gap: 5,
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 1,
        paddingBottom: 1,
        borderRadius: 16,
        background: "#1890ff",
      }}
    >
      <Switch
        checked={settings.MobileContentOptimizationEnabled}
        onChange={(checked) => handleSettingChange('MobileContentOptimizationEnabled', checked)}
        disabled={false}
        checkedChildren="ON"
        unCheckedChildren="OFF"
        icon={false}
        loading={false}
        size="medium"
        text
      />
    </div>
    <p
      style={{
        width: 462,
        position: "absolute",
        left: 438,
        top: 28,
        fontSize: 22,
        textAlign: "left",
        color: "rgba(0,0,0,0.85)",
      }}
    >
      Mobile Content Optimization
    </p>
    <p
      style={{
        width: 464,
        position: "absolute",
        left: 438,
        top: 56,
        fontSize: 16,
        textAlign: "left",
        color: "rgba(0,0,0,0.45)",
      }}
    >
      Enhance your website content for mobile users. Adjust the AI Creativity Level to seamlessly
      align with your brand's voice and tone. Our AI engine generates a variety of mobile-optimized
      variants, rigorously testing them through A/B testing to identify the most effective content.
      This ensures your message resonates on mobile while reinforcing your brand identity.
    </p>
    <img
      src="https://login.seatext.com/website-content-mobile.png"
      style={{
        width: 362,
        height: 252,
        position: "absolute",
        left: 27,
        top: 25,
        objectFit: "cover",
      }}
    />
  </div>
  <div
    style={{
      flexGrow: 0,
      flexShrink: 0,
      width: 1086,
      height: 280,
      position: "relative",
      background: "#f5f5f5",
    }}
  >
    <div
      style={{
        width: 1086,
        height: 280,
        position: "absolute",
        left: "-0.5px",
        top: "-0.5px",
        background: "#f5f5f5",
      }}
    />
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "absolute",
        left: 1010,
        top: 28,
        gap: 5,
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 1,
        paddingBottom: 1,
        borderRadius: 16,
        background: "#1890ff",
      }}
    >
      <Switch
        checked={settings.MobileOptimizedCTAsEnabled}
        onChange={(checked) => handleSettingChange('MobileOptimizedCTAsEnabled', checked)}
        disabled={false}
        checkedChildren="ON"
        unCheckedChildren="OFF"
        icon={false}
        loading={false}
        size="medium"
        text
      />
    </div>
    <p
      style={{
        width: 462,
        position: "absolute",
        left: 438,
        top: 28,
        fontSize: 22,
        textAlign: "left",
        color: "rgba(0,0,0,0.85)",
      }}
    >
      Mobile-Optimized Calls-to-Action
    </p>
    <p
      style={{
        width: 464,
        position: "absolute",
        left: 438,
        top: 56,
        fontSize: 16,
        textAlign: "left",
        color: "rgba(0,0,0,0.45)",
      }}
    >
      Maximize conversions with AI-rewritten CTAs and buttons tailored for mobile. Our AI engine
      analyzes your website content and industry to craft compelling calls to action specifically
      designed for mobile users. Easily A/B test different options to discover what resonates best
      with your target audience, ensuring every tap turns into a valuable conversion.
    </p>
    <img
      src="https://login.seatext.com/ctas-buttons-ctas.png"
      style={{
        width: 362,
        height: 253,
        position: "absolute",
        left: 27,
        top: 25,
        objectFit: "cover",
      }}
    />
  </div>
  <div
    style={{
      flexGrow: 0,
      flexShrink: 0,
      width: 1086,
      height: 280,
      position: "relative",
      background: "#f5f5f5",
    }}
  >
    <div
      style={{
        width: 1086,
        height: 280,
        position: "absolute",
        left: "-0.5px",
        top: "-0.5px",
        background: "#fff",
      }}
    />
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "absolute",
        left: 1010,
        top: 28,
        gap: 5,
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 1,
        paddingBottom: 1,
        borderRadius: 16,
        background: "#1890ff",
      }}
    >
      <Switch
        checked={settings.MobileFriendlyReviewsEnhancementEnabled}
        onChange={(checked) => handleSettingChange('MobileFriendlyReviewsEnhancementEnabled', checked)}
        disabled={false}
        checkedChildren="ON"
        unCheckedChildren="OFF"
        icon={false}
        loading={false}
        size="medium"
        text
      />
    </div>
    <p
      style={{
        width: 462,
        position: "absolute",
        left: 438,
        top: 28,
        fontSize: 22,
        textAlign: "left",
        color: "rgba(0,0,0,0.85)",
      }}
    >
      Mobile-Friendly Customer Reviews
    </p>
    <p
      style={{
        width: 464,
        position: "absolute",
        left: 438,
        top: 56,
        fontSize: 16,
        textAlign: "left",
        color: "rgba(0,0,0,0.45)",
      }}
    >
      Our AI technology enhances customer reviews, making them shine on mobile screens. We refine
      language, correct grammar, and moderate tone to ensure reviews are easy to read and
      professionally presented while preserving the authenticity of the original sentiment. Please
      note that if you wish to remove negative reviews, this must be done manually.
    </p>
    <img
      src="https://login.seatext.com/customer-reviews-mobile.png"
      style={{
        width: 362,
        height: 252,
        position: "absolute",
        left: 27,
        top: 25,
        objectFit: "cover",
      }}
    />
  </div>
  <div
    style={{
      flexGrow: 0,
      flexShrink: 0,
      width: 1086,
      height: 280,
      position: "relative",
      background: "#f6f6f6",
    }}
  >
    <div
      style={{
        width: 1086,
        height: 280,
        position: "absolute",
        left: "-0.5px",
        top: "-0.5px",
        background: "#f6f6f6",
      }}
    />
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "absolute",
        left: 1010,
        top: 28,
        gap: 5,
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 1,
        paddingBottom: 1,
        borderRadius: 16,
        background: "#1890ff",
      }}
    >
      <Switch
        checked={settings.MobileTechinfoEnhancementEnabled}
        onChange={(checked) => handleSettingChange('MobileTechinfoEnhancementEnabled', checked)}
        disabled={false}
        checkedChildren="ON"
        unCheckedChildren="OFF"
        icon={false}
        loading={false}
        size="medium"
        text
      />
    </div>
    <p
      style={{
        width: 462,
        position: "absolute",
        left: 440,
        top: 28,
        fontSize: 22,
        textAlign: "left",
        color: "rgba(0,0,0,0.85)",
      }}
    >
      Mobile-Optimized Technical Content
    </p>
    <p
      style={{
        width: 464,
        position: "absolute",
        left: 440,
        top: 56,
        fontSize: 16,
        textAlign: "left",
        color: "rgba(0,0,0,0.45)",
      }}
    >
      Our AI enhances the presentation of technical information, making complex topics clear and
      engaging on mobile. We refine wording, correct grammar, and optimize readability while
      preserving critical technical details. A/B testing ensures the content resonates with your
      mobile audience, making intricate information accessible and digestible on smaller screens.
    </p>
    <img
      src="https://login.seatext.com/technical-documentation-mobile.png"
      style={{
        width: 362,
        height: 252,
        position: "absolute",
        left: 27,
        top: 25,
        objectFit: "cover",
      }}
    />
  </div>
</div>



<div style={{ height: '15px' }}></div>



            {/* AI Creativity Level and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>AI Creativity Level:</Text>
                <Slider
                  min={1}
                  max={5}
                  step={1}
                  marks={{
                    1: 'Max',
                    2: 'High',
                    3: 'Medium',
                    4: 'Low',
                    5: 'Min',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingCreativityLevel', value)}
                  value={settings.ABtestingCreativityLevel}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Adjust how creative the AI should be. A lower setting means the AI will generate content that's significantly different from the original, while a higher setting limits creativity to minimal changes.
                </Text>
              </Col>
            </Row>
  
            {/* Number of Variants to Create and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Number of Variants to Create:</Text>
                <Slider
                  min={2}
                  max={8}
                  step={1}
                  marks={{
                   
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    6: '6',
                    7: '7',
                    8: '8',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingVariantsTnumber', value)}
                  value={settings.ABtestingVariantsTnumber}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Choose the number of variant outputs. A lower number is suitable for sites with small traffic, while a higher number caters to websites with at least 30K visitors a month.
                </Text>
              </Col>
            </Row>
  
            {/* Preserve Your Brand Voice and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '150px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Preserve Your Brand Voice (0-10):</Text>
                <Slider
                  min={0}
                  max={10}
                  step={1}
                  onChange={(value) => handleSettingChange('ABtestingBrandVoicePreservation', value)}
                  value={settings.ABtestingBrandVoicePreservation}
                  marks={{
                    0: '0',
                    1: '1',
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    6: '6',
                    7: '7',
                    8: '8',
                    9: '9',
                    10: '10',
                    
                  }}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Should AI copy your Brand voice characteristics, keeping it closer to your brand style, or have the ability to be more creative and depart from usual brand patterns? A higher value preserves your brand voice more closely.
                </Text>
              </Col>
            </Row>
  
            {/* A/B Testing Settings */}
            {/* Confidence Level for A/B Testing and its description */}
            <Title level={2} align="center" style={{ marginBottom: '50px' }}>A/B Testing Settings</Title>
  
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Confidence Level for A/B Testing:</Text>
                <Slider
                  min={70}
                  max={95}
                  step={5}
                  marks={{
                    70: '70%',
                    75: '75%',
                    80: '80%',
                    85: '85% Recommended',
                    90: '90%',
                    95: '95%',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingConfidenceLevel', value)}
                  value={settings.ABtestingConfidenceLevel}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  This slider adjusts the confidence level required to determine a winning variant in A/B testing. A higher confidence level means requiring more evidence (data) before making a decision, reducing the risk of false positives but potentially requiring more time and traffic.
                </Text>
              </Col>
            </Row>
  
            {/* Early Elimination of Underperforming Variants and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Early Elimination of Underperforming Variants:</Text>
                <Slider
                  min={60}
                  max={70}
                  step={1}
                  marks={{
                    60: '60%',
                    62: '62%',
                    65: '65% Recommended',
                    67: '67%',
                    70: '70%',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingEarlyElimination', value)}
                  value={settings.ABtestingEarlyElimination}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Adjust how quickly underperforming variants are discarded during A/B testing. A lower threshold is less aggressive, keeping variants longer for more data collection, while a higher threshold more quickly eliminates variants deemed underperforming based on early results.
                </Text>
              </Col>
            </Row>
  

            <Row gutter={[16, 24]} style={{ marginBottom: '50px', alignItems: 'top' }}> {/* Increased vertical space for the last slider */}
                    <Col span={12}>
                        <Text>Introduce AI variants slowly to your visitors:</Text>
                        <Slider
                            min={1}
                            max={6}
                            step={1}
                            marks={{
                                1: '100%',
                                2: '80%',
                                3: '60%',
                                4: '40%',
                                5: '10%',
                                6: '0% ',
                            }}
                            defaultValue={1}
                            onChange={(value) => handleSettingChange('ABtestingTrafficPercentageForVariants', value)}
                           tooltipVisible={false}
                        />
                    </Col>
                    <Col span={12} style={{ paddingLeft: '50px' }}>
                        <Text>
                            This percantage of traffic should see AI variants, put it to 10% if you need to introduce AI variants to your visitors very slowly.  Your original text wil be tested in A/B mix in any case as one of variant.
                        </Text>
                    </Col>
                </Row>



  
          </Card>
        </div>
      </div>
    </div>
  );
  
};

export default Mobile;