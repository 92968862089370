import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';


import { fetchUsageStats } from './utils'; // Adjust the import path as necessary
import { Typography, Card, Slider, Button, Alert, Table, Tooltip, Row, Col } from 'antd';
import { InfoCircleOutlined, CheckCircleFilled } from '@ant-design/icons';

const { Title, Text } = Typography;

export default function BillingAndPayments() {
  const [currentViewCount, setCurrentViewCount] = useState(0);
  const [currentWordCount, setCurrentWordCount] = useState(0);
  const [viewLimit, setViewLimit] = useState(null);
  const [wordLimit, setWordLimit] = useState(null);
  const [currentForecast, setCurrentForecast] = useState(0);
  const [monthlyLimit, setMonthlyLimit] = useState(0);
  const [usageStats, setUsageStats] = useState({ ViewsTotal: 0, PageViewsLimit: 0, WordsUsedTotal: 0, TokensLimit: 0, Bonus: 0, ClientUsage: []  });
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const fetchStats = async () => {
      const result = await fetchUsageStats();
      if (result && result.success) {
        setUsageStats({
          ViewsTotal: result.data.ViewsTotal,
          WordsUsedTotal: result.data.WordsUsedTotal,
          balance: result.data.balance,
          Bonus: result.data.Bonus || 0,
          ClientUsage: result.data.ClientUsage || []
        });
        setCurrentViewCount(result.data.ViewsTotal);
        setCurrentWordCount(result.data.WordsUsedTotal);
        setBalance(result.data.RealBalance || 0);
        
        const currentViewsCost = Math.max(result.data.ViewsTotal, 0) / 1000 * 0.1;
        const currentWordsCost = Math.max(result.data.WordsUsedTotal, 0) / 1000 * 2;
        setCurrentForecast(currentViewsCost + currentWordsCost);
        
        setViewLimit(result.data.PageViewsLimit || Math.round(result.data.ViewsTotal * 1.2));
        setWordLimit(result.data.TokensLimit || Math.round(result.data.WordsUsedTotal * 1.2));
      } else {
        console.error('Failed to fetch usage stats', result ? result.error : 'Unknown error');
      }
    };
    fetchStats();
  }, []);

  useEffect(() => {
    const viewsCost = viewLimit ? Math.max(viewLimit, 0) / 1000 * 0.1 : 0;
    const wordsCost = wordLimit ? Math.max(wordLimit, 0) / 1000 * 2 : 0;
    setMonthlyLimit(viewsCost + wordsCost);
  }, [viewLimit, wordLimit]);

  const createSubscriptionLink = async (planId) => {
    const email = Cookies.get('email');
    if (!email) {
      console.error('Email not found in cookies.');
      return;
    }
    const requestBody = { email, planId };

    const response = await fetch(`https://api.seatext.com:443/client/subscription`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      console.error(`Error ${response.status}: ${response.statusText}`);
      return;
    }

    try {
      const data = await response.json();
      window.location.href = data.url;
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  };

  const setLimits = async () => {
    console.log('Setting limits:', { viewLimit, wordLimit });
    // TODO: Implement API call to set limits
  };

  const turnOffLimits = () => {
    setViewLimit(Math.round(currentViewCount * 1.2));
    setWordLimit(Math.round(currentWordCount * 1.2));
    console.log('Limits turned off');
    // TODO: Implement API call to turn off limits
  };

  const formatNumber = (num) => {
    if (num >= 1000000) return (num / 1000000).toFixed(1) + 'M';
    if (num >= 1000) return (num / 1000).toFixed(1) + 'K';
    return num.toString();
  };

  const calculateRange = (currentValue) => {
    const min = Math.max(Math.round(currentValue * 0.3), 1);
    const max = Math.round(currentValue * 3);
    return [min, max];
  };

  const createMarks = (min, max, current) => {
    const recommendedLimit = Math.round(current * 1.4);
    const marks = {};
    
    // Add min and max
    marks[min] = formatNumber(min);
    marks[max] = formatNumber(max);
  
    // Add current usage and recommended limit
    marks[current] = {
      style: { color: 'blue' },
      label: <Tooltip title="Current Usage"><strong>{formatNumber(current)} - Current</strong></Tooltip>
    };
    marks[recommendedLimit] = {
      style: { color: 'red' },
      label: <Tooltip title="Recommended Limit"><strong>{formatNumber(recommendedLimit)} - Recommended</strong></Tooltip>
    };
  
    // Calculate potential mark positions
    const range = max - min;
    const potentialMarks = [
      min + range * 0.25,
      min + range * 0.5,
      min + range * 0.75,
    ].map(Math.round);
  
    // Filter and add marks that are sufficiently far from existing marks
    potentialMarks.forEach(mark => {
      const isFarEnough = Object.keys(marks).every(existingMark => 
        Math.abs(mark - existingMark) / range > 0.15
      );
  
      if (isFarEnough) {
        marks[mark] = formatNumber(mark);
      }
    });
  
    return marks;
  };

  const [viewMin, viewMax] = calculateRange(currentViewCount);
  const [wordMin, wordMax] = calculateRange(currentWordCount);

  const viewMarks = createMarks(viewMin, viewMax, currentViewCount);
  const wordMarks = createMarks(wordMin, wordMax, currentWordCount);

  const parsedUsageData = usageStats.ClientUsage.map((usage, index, array) => {
    const parts = usage.split(' - ');
    if (parts.length !== 2) return null;

    const [date, details] = parts;
    const detailsParts = details.split(', ');
    if (detailsParts.length !== 3) return null;

    const [words, views, balance] = detailsParts;
    const currentBalance = parseFloat(balance.split('$')[1]) || 0;
    
    let dailyCost = ' N/A';
    if (index < array.length - 1) {
      const nextUsage = array[index + 1];
      const nextBalancePart = nextUsage.split(' - ')[1].split(', ')[2];
      const nextBalance = parseFloat(nextBalancePart.split('$')[1]) || 0;
      dailyCost = Math.abs(nextBalance - currentBalance).toFixed(2);
    }

    return {
      date,
      words: parseInt(words.split(': ')[1]) || 0,
      views: parseInt(views.split(': ')[1]) || 0,
      balance: currentBalance,
      dailyCost
    };
  }).filter(item => item !== null);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'AI Used Words',
      dataIndex: 'words',
      key: 'words',
    },
    {
      title: 'AI Managed Views',
      dataIndex: 'views',
      key: 'views',
    },
    {
      title: 'Balance at End of Day',
      dataIndex: 'balance',
      key: 'balance',
      render: (balance) => `$${balance.toFixed(2)}`
    },
    {
      title: 'Daily Cost',
      dataIndex: 'dailyCost',
      key: 'dailyCost',
      render: (cost) => `$${cost}`
    },
  ]; return (
    <div style={{ maxWidth: 1200, margin: '0 auto', padding: '30px' }}>
      

      <Row gutter={[16, 16]} style={{ marginBottom: 30 }}>
      <Col span={12}>
        <Card 
          className="plan-card"
          style={{ height: '100%', position: 'relative', overflow: 'visible' }}
          bodyStyle={{ paddingTop: '80px' }} 
        >
          <div style={{
            position: 'absolute',
            top: '-20px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '80%',
            backgroundColor: '#4096ff',
            color: 'white',
            padding: '15px 25px',
            borderRadius: '8px',
            textAlign: 'center',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
          }}>
            <Title level={2} style={{ color: 'white', margin: 0 }}>FREE PLAN</Title>
            <Text style={{ color: 'white', fontSize: '16px' }}>Start using AI without any costs</Text>
          </div>
          
          <div style={{ marginTop: '40px' }}> {/* Added margin top for content */}
            <Title level={4} style={{ marginBottom: '30px' }}> {/* Increased bottom margin */}
            ✅ Free to use, without any limits. Apply any of SEATEXT AI models to any number of pages, enjoy free AI-generated rewrites, access translations in 125 languages, and get unlimited AI-managed views of your pages.
            </Title>
            <Text>
            ✅ You can use SEATEXT AI without needing to add a credit card.<br /><br />

  
  ✅ The SMART GDPR COOKIES feature is completely free with unlimited usage, and it does not rely on AI-generated text for rewriting.<br /><br />


   🚫  If your website exceeds the Fair Usage Policy, a small banner will appear to around 3-7% of visitors, only when they show exit intent. This banner, which won't appear on page load or disrupt your users, promotes SEATEXT AI and explains how it helps website owners improve their sites. This should not impact your conversion rate or sales. Based on our data, approximately 0.3% of visitors will click on the banner and be redirected to the SEATEXT website. This limited banner display is the only way we can offer AI features at no cost. The Fair Usage Policy covers up to 200,000 visitors or 10,000 words rewritten by AI.<br /><br />

  🚫  Processing Time for Free Websites.   <br />
  Free websites may take up to two weeks to complete the initial translation or content rewrite process. Requests are processed in a queue, and with a daily translation limit, larger websites may take even longer. This delay will also apply to any updates you make to your site. Please keep this timing in mind for scheduling your content updates.<br /><br />

  🚫 Advanced AI Filtering for Variants <br />
SEATEXT AI includes advanced filtering that validates translation and rewrite variants before publishing them on your site. To ensure high quality, our system uses intensive AI filtering (up to 5X more processing than standard requests). Because of this, the advanced filtering feature is not available on the free plan. As a result, on the free plan, you may occasionally see unverified variants. We recommend manually reviewing AI-generated variants on your website at least once every 2–3 days.
<br /><br />

🚫 Daily Limit on AI-Generated Variants <br />
Free plan users are limited to generating 30 AI-created variants per day using the Variant Editor. This limit helps SEATEXT maintain server capacity and provide AI-generated variants for all users. However, this limit does not apply when AI automatically creates variants for your website.
<br /><br />

  🚫  Access to Premium Models Requires Positive Balance.<br />
  Some advanced SEATEXT models require a positive balance to operate, due to their high resource consumption for advanced rewrites. If your account balance falls below zero, these premium models will no longer generate new content, although content previously generated by these models will still be visible on your site.  In this case, the small exit intent SEATEXT ad allows premium models to continue displaying content, but to generate new content with premium models, you must upgrade to a paid plan. <br /><br />

  🚫 General AI Model for Free Version of SEATEXT  <br />
   The free version of SEATEXT uses a single, general-purpose AI model for all websites on the free plan. While this model delivers good results, it doesn’t perform as well as a dedicated model tailored specifically to your website's unique traffic sources, conversion goals, and audience needs. Creating and training a custom model for each website requires significant GPU resources, which makes it costly. Therefore, this personalized model experience is only available on our paid plan. <br /><br />
</Text>
          </div>
        </Card>
      </Col>
      <Col span={12}>
        <Card 
          className="plan-card"
          style={{ height: '100%', position: 'relative', overflow: 'visible' }}
          bodyStyle={{ paddingTop: '80px' }} 
        >
          <div style={{
            position: 'absolute',
            top: '-20px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '80%',
            background: 'linear-gradient(135deg, #1890ff 0%, #096dd9 100%)',
            color: 'white',
            padding: '15px 25px',
            borderRadius: '8px',
            textAlign: 'center',
            boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
          }}>
            <Title level={2} style={{ color: 'white', margin: 0 }}>PAID PLAN</Title>
            <Text style={{ color: 'white', fontSize: '16px' }}>Advanced features & Premium support</Text>
          </div>

          <div style={{ marginTop: '40px' }}> {/* Added margin top for content */}
            <Text style={{ fontSize: '16px', display: 'block', marginBottom: '20px' }}>
              The paid plan completely removes the small exit intent banner, but you will need to pay for SEATEXT AI usage:
            </Text>
            <ul style={{ margin: '20px 0', fontSize: '16px' }}>
              <li>$0.1 per 1,000 AI-managed views (10 times cheaper than A/B testing tools like VWO.com!)</li>
              <li>$2 per 1,000 AI-generated words</li>
            </ul>
            <Text style={{ display: 'block', marginTop: '20px', marginBottom: '30px' }}>
  Typically, SEATEXT clients spend $25–$120 per month on a paid plan. You can always set a spending limit, and any visitors above this limit will see a small exit-intent banner.
</Text>
            <Text style={{ display: 'block', fontSize: '16px', fontWeight: 500, marginBottom: '20px' }}>
              What else you get:
            </Text>
            <div style={{ marginTop: '20px' }}>
              {[
                'Enhanced content filtering: The paid version applies advanced filters, reducing the time you spend reviewing AI-generated content.',
                'Custom AI model: The free version uses a general AI model, while the paid plan creates a model tailored specifically to your website, learning from your traffic and visitor behavior.',
                'Dedicated support: Receive direct assistance from our CRO team to effectively implement SEATEXT AI and monitor AI activity for faster conversion optimization.',
                'Faster delivery of translations and AI variants: Delivered within minutes vs up to 14 days on free plan.',
                'AI vision model: Analyzes your website, understanding the importance of each text element.',
                'Access to all premium models without any limitations.'
              ].map((benefit, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '16px' }}>
                  <CheckCircleFilled style={{ color: '#52c41a', marginRight: '12px', marginTop: '4px' }} />
                  <Text>{benefit}</Text>
                </div>
              ))}
            </div>
          </div>
        </Card>
      </Col>
    </Row>

    {balance < 0 && (
  <Alert
    message="Warning"
    description="Your balance is below $0. Minimal SEATEXT AI branding will be displayed to 3-7% of your visitors and only when they show exit intent. This should not affect your conversion rate or sales."
    type="warning"
    showIcon
    style={{ marginBottom: 20 }}
  />
)}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
        <Card style={{ width: '32%' }}>
          <Title level={3}>Balance</Title>
          <Text type="secondary">Current balance in your account</Text>
          <Title level={3} style={{ color: balance < 0 ? 'red' : 'rgba(27,31,38,0.72)' }}>${balance.toFixed(2)}</Title>
          {usageStats.Bonus > 0 && (
            <div style={{ marginTop: '10px' }}>
              <Text type="secondary">Bonus</Text>
              <Title level={4} style={{ color: 'green', margin: '0' }}>${usageStats.Bonus.toFixed(2)}</Title>
            </div>
          )}
        </Card>
        <Card style={{ width: '32%' }}>
          <Title level={3}>Current Monthly Usage</Title>
          <Text type="secondary">Based on your current usage</Text>
          <div style={{ marginTop: '10px' }}>
            <Text>AI Words: {formatNumber(currentWordCount)}</Text>
          </div>
          <div>
            <Text>AI Views: {formatNumber(currentViewCount)}</Text>
          </div>
          <Title level={4} style={{ marginTop: '10px', color: 'rgba(27,31,38,0.72)' }}>
            ${currentForecast.toFixed(2)}/mo
          </Title>
        </Card>
        <Card style={{ width: '32%' }}>
          <Title level={3}>Monthly Limit</Title>
          <Text type="secondary">Based on your set limits</Text>
          <Title level={3} style={{ color: 'rgba(27,31,38,0.72)' }}>${monthlyLimit.toFixed(2)}</Title>
        </Card>
      </div>

      <Card title="Set Monthly Limits" style={{ marginBottom: 30 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '100px' }}>
          <div>
            <Text style={{ display: 'block', marginBottom: 40 }}>
              Monthly limit for AI-managed views
            </Text>
            <Slider
              min={viewMin}
              max={viewMax}
              marks={viewMarks}
              tooltipVisible
              onChange={setViewLimit}
              value={viewLimit}
              included={true}
              tipFormatter={value => `Monthly limit: ${formatNumber(value)}`}
            />
          </div>
          <div>
            <Text style={{ display: 'block', marginBottom: 40 }}>
              Monthly limit for AI-rewritten words
            </Text>
            <Slider
              min={wordMin}
              max={wordMax}
              marks={wordMarks}
              tooltipVisible
              onChange={setWordLimit}
              value={wordLimit}
              included={true}
              tipFormatter={value => `Monthly limit: ${formatNumber(value)}`}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
          <Button 
            type="primary" 
            size="large"
            onClick={setLimits}
            style={{ 
              backgroundColor: '#1890ff', 
              color: 'white',
              transition: 'box-shadow 0.3s',
            }}
            onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0 0 10px rgba(0, 0, 255, 0.5)'}
            onMouseLeave={(e) => e.currentTarget.style.boxShadow = 'none'}
          >
            Set Limits
          </Button>
          <Button 
            size="large"
            onClick={turnOffLimits}
            style={{ 
              transition: 'box-shadow 0.3s',
            }}
            onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0 0 10px rgba(0, 0, 255, 0.5)'}
            onMouseLeave={(e) => e.currentTarget.style.boxShadow = 'none'}
          >
            Turn Off Limits
          </Button>
        </div>
      </Card>

      <Title level={3}>Add your card for metered usage</Title>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: 20 }}>
        <div style={{ width: '38%' }}>
          <Button 
            type="primary" 
            size="large"
            onClick={() => createSubscriptionLink('price_1PcyPCFdMb3BNSidDkU25MJm')} 
            style={{ 
              marginBottom: 10, 
              backgroundColor: '#1890ff', 
              color: 'white',
              transition: 'box-shadow 0.3s',
            }}
            onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0 0 10px rgba(0, 0, 255, 0.5)'}
            onMouseLeave={(e) => e.currentTarget.style.boxShadow = 'none'}
          >
            Add your corporate card
          </Button>
          <Button 
            type="primary" 
            size="large"
            href="https://seatext.com/cancel"
            style={{ 
              marginBottom: 10, 
              backgroundColor: '#1890ff', 
              color: 'white',
              transition: 'box-shadow 0.3s',
            }}
            onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0 0 10px rgba(0, 0, 255, 0.5)'}
            onMouseLeave={(e) => e.currentTarget.style.boxShadow = 'none'}
          >
            Cancel your subscription
          </Button>
          <div>
            <img 
              src="https://images.ctfassets.net/fzn2n1nzq965/4M6d6BSWzlgsrJx8rdZb0I/733f37ef69b5ca1d3d33e127184f4ce4/Powered_by_Stripe.svg" 
              alt="Powered by Stripe" 
              width="150" 
              height="34" 
              style={{ marginTop: 10 }}
            />
          </div>
        </div>
        <div style={{ width: '58%' }}>
          <Text style={{ color: '#626262' }}>
            To ensure uninterrupted service and maintain a professional appearance without branding on your page, we strongly recommend adding a valid credit card before using our services. If your balance reaches zero, SEATEXT will automatically display branding on your page. By adding a credit card, you can prevent this interruption.

            Please note:

            • Add a real credit card, not a prepaid card.

            • Ensure the card has sufficient balance and a long expiration date.

            • Adding or changing cards in Stripe can be challenging, so choose your primary card carefully.

            • Only Stripe has access to your credit card information, not SEATEXT.

            • You can cancel your subscription at any time with one click.

            • SEATEXT typically does not provide refunds.

        

            By maintaining an active subscription, you guarantee uninterrupted service and a branded-free experience.
          </Text>
        </div>
      </div>

      {parsedUsageData.length > 0 && (
        <Card title="Your Usage per Day" style={{ marginTop: 30 }}>
          <Table 
            dataSource={parsedUsageData} 
            columns={columns} 
            pagination={false}
            rowKey="date"
          />
        </Card>
      )}
    </div>
  );
}