import React, { useState, useEffect } from 'react';
import { Typography, Card, Switch, message, Button, Alert } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { fetchSettingsFromServer, sendCustomerProfilesToServer } from './utils';
import AIPersonalizationFeatures from './aipersonalisationfeatures';
import PersonalizedVariantEditor from './PersonalizedVariantEditor';
import Cookies from 'js-cookie';

const { Title, Paragraph } = Typography;

function AIMarketingPersonalisation(props) {
  const [profile, setProfile] = useState({
    allowAIUseGoogleClickId: false,
    allowAIUseFacebookId: false,
    allowAIUseLinkedInId: false,
    allowAIUseCDPData: false,
    allowAIUseLocation: false,
    allowAIUseDeviceType: false,
    allowAIUseDayOfWeek: false,
    allowAIUseReferralSource: false,
    allowAIUseVisitorHistory: false,
    allowAIUseSeasonality: false,
    allowAIUseIndustryVertical: false,
  });
  const [editorVisible, setEditorVisible] = useState(false);
  const [modifiedUrl, setModifiedUrl] = useState('');

  useEffect(() => {
    let initialUrl = props.context?.url || '';

    if (!initialUrl) {
      const domainName = Cookies.get('domainName');
      if (domainName) {
        initialUrl = `${domainName} Whole website`;
      }
    }

    setModifiedUrl(initialUrl);

    fetchSettingsFromServer(initialUrl)
      .then(settings => setProfile(settings))
      .catch(error => console.error('Failed to fetch settings:', error));
  }, [props.context?.url]);

  const updateProfile = (field, value) => {
    setProfile(prevProfile => ({ ...prevProfile, [field]: value }));
  };

  const saveProfileData = () => {
    sendCustomerProfilesToServer(profile)
      .then(response => {
        if (response.success) {
          message.success('Profile saved successfully');
        } else {
          message.error('Failed to save profile: ' + response.error);
        }
      })
      .catch(error => {
        console.error('Failed to save profile:', error);
        message.error('Failed to save profile. Please try again.');
      });
  };

  const renderSwitch = (field, label, description) => (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
      <Switch 
        checked={profile[field]}
        onChange={(checked) => updateProfile(field, checked)}
        style={{ marginRight: '10px' }}
      />
      <div>
        <div style={{ fontWeight: 'bold' }}>{label}</div>
        <div style={{ fontSize: '0.9em', color: '#888' }}>{description}</div>
      </div>
    </div>
  );

  return (
    <div style={{ width: '1400px', maxWidth: '1900px', minWidth: '1200px' }}>
      <Title 
        level={1} 
        align="left" 
        style={{ 
          fontSize: '24px', 
          fontWeight: 'bold',
          marginTop: '20px',
          marginLeft: '30px'
        }}
      >
        AI Personalizes Your Landing Pages Based on Comprehensive Data Inputs
      </Title>
      <Card style={{ margin: '20px 30px' }}>
        <Paragraph>
        Seatext AI utilizes cutting-edge machine learning algorithms to forecast the most impactful text variations for visitors, taking into account a multitude of factors such as ad campaign data, user demographics, and contextual cues. To maximize the accuracy and effectiveness of personalization, we suggest enabling as many parameters as possible to create a robust dataset for the AI model. By leveraging your existing text variations as a starting point, Seatext AI can begin to refine its matching process. Upon activation, this AI technology will immediately start influencing all other AI models, enhancing the overall personalization experience.
        </Paragraph>

        <Alert
          message="Important Information"
          description={
            <ul>
              <li>You need to activate General AI to create textual variants. Without activating it, Personalization AI won't have variants to work with.</li>
              <li>Personalization AI will override General AI settings and variant performance ratings in the Variant Editor, as performance will now be measured per group of users rather than per variant.</li>
              <li>You can activate any other AI features, such as translation or AI for online stores. All of these support personalization as a feature.</li>
            </ul>
          }
          type="info"
          showIcon
          style={{ marginBottom: '20px' }}
        />
        
        <Title level={4}>Ad Platform Data</Title>
        {renderSwitch('allowAIUseGoogleClickId', 'Use Google Click ID', 'Personalizes content based on Google Ads campaign data, helping to tailor the experience for visitors from specific Google ad campaigns.')}
        {renderSwitch('allowAIUseFacebookId', 'Use Facebook Click ID', 'Utilizes Facebook ad data to customize content for visitors coming from Facebook ad campaigns, enhancing relevance for these users.')}
        {renderSwitch('allowAIUseLinkedInId', 'Use LinkedIn Click ID', 'Leverages LinkedIn ad information to personalize content for visitors from LinkedIn campaigns, particularly useful for B2B contexts.')}
        
        <Title level={4}>User Data</Title>
        {renderSwitch('allowAIUseCDPData', 'Use CDP Data', 'Connects with your Customer Data Platform to use rich, first-party data for personalization. Note: This requires connecting your CDP platform via JS injection. Please refer to our documentation for integration details: https://seatext.com/documentation')}
        {renderSwitch('allowAIUseVisitorHistory', 'Use Visitor History', 'Takes into account the visitor\'s previous interactions with your site to provide a more tailored experience based on their past behavior and preferences.')}

        <Title level={4}>Contextual Data</Title>
        {renderSwitch('allowAIUseLocation', 'Use Visitor Location', 'Adapts content based on the visitor\'s geographic location, allowing for region-specific messaging or offers.')}
        {renderSwitch('allowAIUseDeviceType', 'Use Device Type', 'Optimizes content presentation based on whether the visitor is using a mobile device or desktop computer, ensuring the best experience for each platform.')}
        {renderSwitch('allowAIUseDayOfWeek', 'Use Day of Week', 'Adjusts content based on the day of the week, useful for businesses with different offerings or messaging priorities throughout the week.')}
        {renderSwitch('allowAIUseSeasonality', 'Use Seasonality', 'Incorporates seasonal factors into content personalization, ideal for businesses with seasonal products or services.')}

        <Title level={4}>Additional Data</Title>
        {renderSwitch('allowAIUseReferralSource', 'Use Referral Source', 'Customizes the experience based on where the visitor came from, such as a search engine, social media, or a referring website.')}
        {renderSwitch('allowAIUseIndustryVertical', 'Use Industry Vertical', 'Tailors content to the visitor\'s industry or business sector, particularly valuable for B2B companies serving multiple industries.')}

        <Button 
          type="primary" 
          onClick={saveProfileData} 
          style={{ marginTop: '20px' }}
        >
          Save Settings
        </Button>

        <PersonalizedVariantEditor
          visible={editorVisible}
          onClose={() => setEditorVisible(false)}
          url={modifiedUrl}
          language="English"
          personalizationId={profile?.personalizationId}
          cdpData={profile?.allowAIUseCDPData ? profile.cdpData : ''}
        />

        
      </Card>
    </div>
  );
}

export default AIMarketingPersonalisation;