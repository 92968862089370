import React from 'react';
import { Layout } from 'antd';
const { Content } = Layout;

export default function FAQPage() {
  return (
    <Layout style={{ height: '3000vh' }}>
      <Content style={{ padding: '0', margin: '0', overflow: 'hidden' }}>
        <iframe 
          src="https://seatext.com/faq-seatext-ai" 
          style={{ width: '100%', height: '5000px', border: 'none' }}
          title="FAQ"
        />
      </Content>
    </Layout>
  );
}
