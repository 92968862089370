import React from 'react';
import { Typography, Divider } from 'antd';

const { Title, Paragraph } = Typography;

function LicenseAgreementPage() {
  return (
    <div style={{ padding: '50px', maxWidth: '900px', margin: '0 auto' }}>
      <Title level={1} style={{ textAlign: 'center' }}>License Agreement</Title>
      <Divider />

      <Title level={2}>SEATEXT Corp User Agreement</Title>
      <Paragraph>
        This User Agreement ("Agreement") is entered into between SEATEXT Corp, a Delaware corporation ("Company," "we," "our," or "us") and the user ("User," "you," or "your") and governs the use of SEATEXT Corp's software-as-a-service ("SaaS") platform and related services ("Service"). By using the Service, you agree to be bound by the terms and conditions outlined in this Agreement.
      </Paragraph>
      <Divider />

      <Title level={3}>1. Acceptance of Terms</Title>
      <Paragraph>
        By accessing or using SEATEXT Corp's Service, you acknowledge that you have read, understood, and agree to comply with this Agreement, as well as our Privacy Policy. If you do not agree to these terms, please do not use the Service.
      </Paragraph>

      <Title level={3}>2. Use of the Service</Title>
      <Paragraph>
        <strong>2.1. Email Requirement:</strong> To access and use the Service, you must provide a valid email address. This email will be used for official communication from SEATEXT Corp, including but not limited to service notifications and warnings concerning overuse or misuse of the platform.
        <br /><strong>2.2. Communication:</strong> By providing your email address, you consent to receive mandatory service communications that are essential for the ongoing use of our platform. You acknowledge that these communications are not subject to opt-out provisions as they are crucial for your interaction with SEATEXT Corp staff and for receiving important service alerts.
        <br /><strong>2.3. Eligibility:</strong> You must be at least 18 years old and have the legal capacity to enter into contracts to use the Service.
        <br /><strong>2.4. Access:</strong> You are responsible for obtaining and maintaining all equipment and services needed to access and use the Service.
        <br /><strong>2.5. Usage Restrictions:</strong> You agree not to:
        <ul>
          <li>Use the Service for any illegal, fraudulent, or unauthorized purposes.</li>
          <li>Attempt to access, tamper with, or use non-public areas of the Service or our systems.</li>
          <li>Upload, transmit, or share any content that violates intellectual property rights, privacy, or any applicable laws.</li>
          <li>Interfere with or disrupt the integrity or performance of the Service.</li>
          <li>Engage in any activity that could harm the Service or its users.</li>
        </ul>
      </Paragraph>

      <Title level={3}>3. Intellectual Property</Title>
      <Paragraph>
        <strong>3.1. Ownership:</strong> SEATEXT Corp retains all right, title, and interest in and to the Service, including all associated intellectual property rights.
        <br /><strong>3.2. User Content:</strong> You grant SEATEXT Corp a non-exclusive, royalty-free license to use, reproduce, adapt, publish, translate, and distribute content you submit or create using the Service for the purpose of providing the Service.
      </Paragraph>

      <Title level={3}>4. Fees and Payments</Title>
      <Paragraph>
        <strong>4.1. Subscription Fees:</strong> If applicable, you agree to pay all fees associated with your use of the Service as specified in the pricing plan you select.
        <br /><strong>4.2. Payment Information:</strong> You must provide accurate billing and payment information and keep it up to date. SEATEXT Corp may suspend or terminate your access to the Service if fees are not paid on time.
      </Paragraph>

      <Title level={3}>5. Privacy</Title>
      <Paragraph>
        Your use of the Service is subject to our Privacy Policy, which explains how we collect, use, and protect your personal information.
      </Paragraph>

      <Title level={3}>6. Termination</Title>
      <Paragraph>
        SEATEXT Corp reserves the right to terminate or suspend your access to the Service, with or without cause, at any time and without notice.
      </Paragraph>

      <Title level={3}>7. Disclaimers</Title>
      <Paragraph>
        The Service is provided "as is," and SEATEXT Corp makes no warranties regarding its accuracy, completeness, or reliability. We disclaim all warranties, whether express, implied, or statutory.
      </Paragraph>

      <Title level={3}>8. Limitation of Liability</Title>
      <Paragraph>
        In no event shall SEATEXT Corp be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, arising out of your use or inability to use the Service.
      </Paragraph>

      <Title level={3}>9. Governing Law</Title>
      <Paragraph>
        This Agreement is governed by and construed in accordance with the laws of the state of Delaware, United States of America, without regard to its conflict of law principles.
      </Paragraph>

      <Title level={3}>10. Miscellaneous</Title>
      <Paragraph>
        <strong>10.1. Entire Agreement:</strong> This Agreement constitutes the entire agreement between you and SEATEXT Corp and supersedes all prior agreements and understandings.
        <br /><strong>10.2. Modifications:</strong> SEATEXT Corp reserves the right to update and modify this Agreement at any time. We will notify you of any material changes via email or through the Service.
      </Paragraph>

      <Title level={3}>11. Limitation of Liability and Indemnification</Title>
      <Paragraph>
        <strong>11.1. Limitation of Liability:</strong> To the fullest extent permitted by applicable law, SEATEXT Corp, its affiliates, officers, employees, agents, suppliers, and licensors will not be liable for (a) any punitive, special, indirect, consequential, incidental, or exemplary damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of, or inability to access or use the Service; (b) any damage, loss, or injury resulting from hacking, tampering, or other unauthorized access or use of the Service or your account or the information contained therein; (c) any errors, mistakes, or inaccuracies of content; or (d) personal injury or property damage, of any nature whatsoever, resulting from your access to or use of our Service.
        <br /><strong>11.2. Indemnification:</strong> You agree to defend, indemnify, and hold harmless SEATEXT Corp, its affiliates, officers, directors, employees, and agents, from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your breach of this Agreement or your use of the Service, including, but not limited to, any use of the Service's content, services, and products other than as expressly authorized in this Agreement or your use of any information obtained from the Service.
        <br /><strong>11.3. Cap on Liability:</strong> Under no circumstances will the total aggregate liability of SEATEXT Corp and its affiliates, officers, employees, agents, suppliers, and licensors arising out of or in connection with this Agreement or the Service exceed the lesser of the amount you have paid to SEATEXT Corp for use of the Service in the last six months or one hundred U.S. dollars ($100).
        <br /><strong>11.4. Exclusions:</strong> Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations and exclusions may not apply to you.
      </Paragraph>

      <Paragraph>
        By using the Service, you agree to this User Agreement. If you have any questions or concerns, please contact us at 511 E. San Ysidro Blvd #713 San Ysidro, CA 92173 Sergei Glukhov or by email sgluhov@seatext.com
      </Paragraph>
    </div>
  );
}

export default LicenseAgreementPage;
