import React from 'react';
import Lottie from 'react-lottie';

const LottieAnimation = ({ animationPath, width, height, ...otherProps }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: null,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
    path: animationPath // Use the animationPath prop here
  };

  return (
    <Lottie 
      options={defaultOptions}
      width={width} 
      height={height} 
      {...otherProps} 
    />
  );
};
export default LottieAnimation;