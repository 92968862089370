import React, { useState } from 'react';
import { Form, Button, Menu, message } from 'antd';
import { deleteUserData } from './utils'; // Adjust the import path as necessary

function SettingsPage() {
  const [selectedOption, setSelectedOption] = useState('freeze'); // Default option set to 'freeze'

  const onFinish = async () => {
    if (selectedOption === 'freeze') {
        // Handle freeze logic
        message.success('Account frozen successfully!');
    } else if (selectedOption === 'delete') {
      try {
        const response = await deleteUserData();
        if (response.success) {
          message.success('Database deletion successful!');
        } else {
          message.error(`Error: ${response.error}`);
        }
      } catch (error) {
        message.error('Failed to delete database: Network error');
      }
    }
  };

  // Style object for the card
  const cardStyle = {
    boxShadow: 'none', // Remove shadow
    border: '1px solid #ccc', // Add a 1px solid border with a light grey color
    margin: '0 auto', // Center horizontally
    padding: '75px', // Padding inside the card
    backgroundColor: 'white',
    width: '90%', // 90% of the viewport width
    height: '60vh', // 60% of the viewport height
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative', // Added for potential positioning needs
    top: 'calc(20% + 200px)', // Move top edge to the middle of the screen and additional 200px lower
    transform: 'translateY(-50%)', // Center vertically by shifting up by half its own height
  };
  
  // Adjusted global container style to align items in the center
  const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  };

  // Style object for menu items
  const menuItemStyle = {
    marginBottom: '20px',
  };

  // Style object for form items
  const formItemStyle = {
    marginBottom: '20px',
  };

  // Style object for text and titles
  const textStyle = {
    marginBottom: '20px',
  };

  return (
    <div style={cardStyle}>
      <div style={containerStyle}>
        <Menu
          mode="vertical"
          selectedKeys={[selectedOption]}
          onClick={({ key }) => setSelectedOption(key)}
          style={{ marginRight: '20px', width: '300px' }} // Adjust width as needed
        >
          <Menu.Item key="freeze" style={menuItemStyle}>Freeze Account</Menu.Item>
          <Menu.Item key="delete" style={menuItemStyle}>Delete Text Database</Menu.Item>
        </Menu>

        <div style={{ paddingLeft: '20px', flex: 1 }}>
          {selectedOption === 'freeze' && (
            <>
              <h2 style={textStyle}>Freeze Account</h2>
              
              {/* Empty div for spacing */}
              <div style={{ height: '15px' }}></div>
              <p style={textStyle}>We understand there are times when you might need to take a step back. Whether you wish to temporarily halt your SEATEXT usage or are facing financial constraints, freezing your account is an option. By doing this, you preserve all your data, preventing any deletion or expiration, for up to 3 years.</p>
              <Form
                name="freezeAccountForm"
                initialValues={{ remember: true }}
                onFinish={onFinish}
              >
                {/* Empty div for spacing */}
                <div style={{ height: '15px' }}></div>
                <Form.Item style={formItemStyle}>
                  <Button type="primary" htmlType="submit">
                    Freeze Account
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}

          {selectedOption === 'delete' && (
            <>
              <h2 style={textStyle}>Delete Your Account's Database</h2>
              <p style={textStyle}>
                Warning: This action is irreversible! Deleting your database means that SEATEXT will permanently remove all associated data...
              </p>
              <Form
                name="deleteAccountForm"
                initialValues={{ remember: true }}
                onFinish={onFinish}
              >
                <Form.Item style={formItemStyle}>
                  <Button type="danger" htmlType="submit">
                    Delete Database
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default SettingsPage;
