import React from 'react';
import { Typography, Divider } from 'antd';

const { Title, Text } = Typography;

function BetaFeatureAccessDenied() {
  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <div style={{ width: '100%', height: '60vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ width: '60%', maxWidth: '500px' }}>
          <Title level={2}>Beta Feature - Beta Access Needed</Title>
          <Divider />
          <Text>
            Your account still does not have access to beta features yet. Please contact your sales manager to get access.
          </Text>
        </div>
      </div>
    </div>
  );
}

export default BetaFeatureAccessDenied;